.button {
    cursor: pointer;
    overflow: hidden;
    appearance: none;
    background-color: var(--color-btn);
    color: #fff;
    border: none;
    border-radius: 999px;
    font-size: 0.8rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-weight: 300;
    outline: none;

    opacity: 1;
    padding: 1rem 2rem;
    transition: padding 0.5s ease, opacity 0.5s;

    &:hover {
        opacity: 0.85;
        padding: 1rem 2.5rem;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.75;
        padding: 1rem 2rem;
    }
}
