.box {
    padding: 2rem;
    border-radius: 5px;
    background-color: var(--color-box-grey);
    text-align: center;
    width: 100%;
    max-width: 50rem;
    margin: 0.5rem auto;
    color: var(--color-box-text);

    a {
        color: var(--color-box-text);
        font-weight: 500;
        text-decoration: none;
        border-bottom: 1px solid var(--color-box-text);
        opacity: 1;
        white-space: nowrap;

        transition: opacity 0.3s;

        &:hover {
            opacity: 0.75;
        }
    }
}

.fail {
    composes: box;
    background-color: var(--color-box-red);
}

.success {
    composes: box;
    background-color: var(--color-box-green);
}
