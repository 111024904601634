@import "../../assets/styles/mixins";

input {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    appearance: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;

    &:focus {
        outline: none;
        border-color: var(--color-main);
        box-shadow: inset 0 0 0 1px var(--color-main);
    }

    &[type="number"] {
        text-align: center;
    }

    @include placeholder {
        opacity: 0.5;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
