/* PostCSS Normalize */
//@import-normalize;
body {
    font-family: "Assistant";
}

/* Global Style */
@import "./assets/styles/global";

/* Colors */
@import "./assets/styles/colors";

/* Mixins */
@import "./assets/styles/mixins";
