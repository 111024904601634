.checker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > *:last-child {
        margin-bottom: 0;
    }
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1.75rem auto;
    width: 100%;

    button {
        margin: 0.35rem 1rem;
    }
}

.result,
.errors {
    padding: 1.5rem;
    border-radius: 5px;
    background-color: var(--color-box-grey);
    text-align: center;
    color: var(--color-box-text);
}

.risky {
    background-color: var(--color-box-red);
}

.notRisky {
    background-color: var(--color-box-green);
}

.errors {
    list-style: none;
}
