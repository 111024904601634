$color-main: #303030;
$color-text: #303030;
$color-btn: #303030;

$color-box-text: #fff;
$color-box-grey: #303030;
$color-box-green: #68d083;
$color-box-red: #dc3939;

:root {
    --color-main: #{$color-main};
    --color-text: #{$color-text};
    --color-btn: #{$color-btn};

    --color-box-text: #{$color-box-text};
    --color-box-grey: #{$color-box-grey};
    --color-box-green: #{$color-box-green};
    --color-box-red: #{$color-box-red};
}
